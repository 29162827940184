var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-select',_vm._g(_vm._b({attrs:{"items":_vm.teams,"multiple":"","dense":"","hide-details":"","rounded":"","disabled":!_vm.hasProjectId,"small-chips":"","flat":"","outlined":"","item-value":"id","item-text":"name","menu-props":{ offsetY: true, disabled: true }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var team = ref.item;
return [_c('v-chip',{staticClass:"mt-1",attrs:{"x-small":"","light":""}},[_vm._v(" "+_vm._s(team.name)+" ")])]}}],null,true),model:{value:(_vm.tmpSelectedTeams),callback:function ($$v) {_vm.tmpSelectedTeams=$$v},expression:"tmpSelectedTeams"}},'v-select',attrs,false),on))]}}])},[_c('v-card',{attrs:{"max-width":"468px"}},[_c('v-card-title',{staticClass:"backgroundAccent darken-1 white--text subtitle-2"},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"MSelectUserTeams component"}},[_vm._v(" Which teams this user belongs to? ")])]),_c('v-card-subtitle',{staticClass:"backgroundAccent darken-1 white--text caption"},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"MSelectUserTeams component"}},[_vm._v(" You can select more than one. ")])]),_c('div',{staticStyle:{"max-height":"300px","overflow-y":"auto"}},[_c('v-list',{attrs:{"subheader":"","dense":""}},[_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.tmpSelectedTeams),callback:function ($$v) {_vm.tmpSelectedTeams=$$v},expression:"tmpSelectedTeams"}},_vm._l((_vm.teams),function(team,i){return _c('v-list-item',{key:i,staticStyle:{"min-height":"32px","height":"32px"},attrs:{"value":team,"color":"secondary","disabled":_vm.updating},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"dense":"","color":"secondary"}})],1),_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',[_vm._v(_vm._s(team.name))])],1)]}}],null,true)})}),1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"backgroundAccent darken-1 justify-space-between pa-4"},[_c('div',{staticClass:"d-flex flex-column justify-end ml-4"},[_c('v-btn',{attrs:{"loading":_vm.updating,"disabled":_vm.teamsDidNotChange || _vm.updating,"dark":!_vm.teamsDidNotChange,"small":"","depressed":"","color":"secondary"},on:{"click":_vm.submit}},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"caption",attrs:{"translate-context":"MSelectUserTeams component save button"}},[_vm._v(" Save ")])]),_c('v-btn',{staticClass:"mt-2",attrs:{"disabled":_vm.teamsDidNotChange || _vm.updating,"dark":"","text":"","small":"","color":"warning"},on:{"click":_vm.resetSelectedTeams}},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"caption",attrs:{"translate-context":"MSelectUserTeams component reset button"}},[_vm._v(" Reset ")])])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }