<template>
  <v-card>
    <v-tabs
      v-model="userTab"
      background-color="backgroundAccent lighten-2"
      icons-and-text
      height="64px"
      class="user-tabs"
    >
      <v-tab
        v-for="(tab) in userTabItems"
        :key="tab.tab"
        class="caption-xs font-weight-bold"
      >
        {{ tab.title }}

        <v-icon
          v-if="tab.icon"
          small
        >
          {{ tab.icon }}
        </v-icon>
      </v-tab>

      <div
        v-if="selectedUser"
        class="flex-grow-1 d-flex justify-center align-end flex-column mx-4"
      >
        <div
          class="caption-xs mb-1"
          v-translate
          translate-context="MUserEditorTabs label for resend registration link"
        >
          Need new registration link?
        </div>

        <v-btn
          x-small
          outlined
          color="secondary"
          v-translate
          translate-context="MUserEditorTabs Button for resend registration link"
          :to="{
            name: 'invite-user',
            params: {
              userId: selectedUser.id,
            },
            query: {
              step: 3,
            },
          }"
        >
          Re-send
        </v-btn>
      </div>
    </v-tabs>

    <v-tabs-items
      v-model="userTab"
      touchless
    >
      <v-tab-item
        v-for="tab in userTabItems"
        :key="tab.title"
      >
        <component
          :is="tab.component"
          :user="selectedUser"
          :project-id="projectId"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
  import MUserOtherProjectsTab from '@/components/userTabs/MUserOtherProjectsTab';
  import MUserBasicInfoTab from '@/components/userTabs/MUserBasicInfoTab';

  export default {
    components: {
      MUserOtherProjectsTab,
      MUserBasicInfoTab,
    },

    props: {
      selectedUser: {
        type: Object,
        required: true,
      },

      projectId: {
        type: Number,
        default: null,
      },
    },

    // watch: {}

    data: () => ({
      /**
       * Selected user (expanded row)
       */
      userTab: 0,
    }),

    computed: {
      userTabItems() {
        return [
          {
            title: this.$pgettext('MUsersPage tabs for selected user', "Manage user's projects"),
            component: MUserOtherProjectsTab,
            icon: 'home',
          },
          {
            title: this.$pgettext('MUsersPage tabs for selected user', "Manage user's info"),
            component: MUserBasicInfoTab,
            icon: 'settings',
          },
        ];
      },
    },
  };
</script>
