<template>
  <div
    class="full-height"
  >
    <v-card
      tile
    >
      <v-card-title
        class="justify-space-between"
      >
        <div>
          <span
            v-translate
            translate-context="MUsersPage title"
          >
            Users
          </span>

          <v-menu
            offset-y
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                color="secondary"
                x-small
                text
                icon
                class="mt-n1 ml-1"
                style="cursor: help;"
                v-on="on"
              >
                <v-icon>
                  help_outline
                </v-icon>
              </v-btn>
            </template>

            <v-card
              light
              max-width="400px"
              class="pb-2"
            >
              <v-card-subtitle
                class="text-uppercase caption font-weight-bold pb-0"
              >
                <span
                  v-translate
                  translate-context="MUsersPage how to manage users tooltip"
                >
                  How to create new users?
                </span>
              </v-card-subtitle>

              <v-card-text>
                <span
                  v-translate
                  translate-context="MUsersPage how to manage users tooltip"
                >
                  You can create new users by clicking the top
                  right corner button that says CREATE USER.
                </span>
              </v-card-text>

              <v-card-subtitle
                class="text-uppercase caption font-weight-bold pb-0"
              >
                <span
                  v-translate
                  translate-context="MUsersPage how to manage users tooltip"
                >
                  How to add existing users to new worksites?
                </span>
              </v-card-subtitle>

              <v-card-text>
                <ol>
                  <li>
                    <span
                      v-translate
                      translate-context="MUsersPage how to manage users tooltip"
                    >
                      Find a user from the list. Click to expand the row.
                    </span>
                  </li>

                  <li>
                    <span
                      v-translate
                      translate-context="MUsersPage how to manage users tooltip"
                    >
                      Select a worksite to which you want to assign this user.
                    </span>
                  </li>

                  <li>
                    <span
                      v-translate
                      translate-context="MUsersPage how to manage users tooltip"
                    >
                      Select teams of which tasks this user will be working on.
                    </span>
                  </li>
                </ol>
              </v-card-text>

              <v-card-subtitle
                class="text-uppercase caption font-weight-bold pb-0"
              >
                <span
                  v-translate
                  translate-context="MUsersPage how to manage users tooltip"
                >
                  Need more help?
                </span>
              </v-card-subtitle>

              <v-card-text>
                <span
                  v-translate
                  translate-context="MUsersPage how to manage users tooltip"
                >
                  Call us
                </span>

                <a
                  href="tel://+358449135409"
                  class="caption"
                >
                  +358 44 9135 409 (Mikko Hell)
                </a>
              </v-card-text>
            </v-card>
          </v-menu>
        </div>

        <div
          class="ml-4"
        >
          <v-btn
            small
            rounded
            color="secondary"
            :to="{
              name: 'invite-user'
            }"
          >
            <v-icon
              small
              left
            >
              add
            </v-icon>

            <span
              v-translate
              translate-context="MUsersPage add new user button"
            >
              Create user
            </span>
          </v-btn>
        </div>
      </v-card-title>

      <v-card-subtitle>
        <span
          v-if="companyById[project.companyId]"
          v-translate="{
            companyName: companyById[project.companyId].name,
          }"
          translate-context="MUsersPage subtitle"
        >
          %{ companyName } — Manage organization & worksite users
        </span>

        <span
          v-else
          translate-context="MUsersPage subtitle"
        >
          Manage all users in Mestamaster
        </span>
      </v-card-subtitle>

      <v-card-text
        class="pb-2"
      >
        <div
          class="d-flex full-width justify-space-between"
          :class="{
            'flex-column': isMobile,
          }"
        >
          <div
            class="flex-grow-1"
            style="max-width: 400px;"
          >
            <v-autocomplete
              v-model="selectedProjectId"
              :items="projects"
              dense
              solo
              rounded
              light
              chips
              hide-details
              :prepend-inner-icon="isMobile ? null : 'filter_list'"
              small-chips
              deletable-chips
              item-value="id"
              item-text="pid"
              :label="$pgettext('MUsersPage', 'All organization users')"
              style="cursor: pointer;"
            >
              <template #item="{ item: project, on, attrs }">
                <v-list-item
                  v-bind="attrs"
                  dense
                  v-on="on"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ project.pid }}
                    </v-list-item-title>

                    <v-list-item-subtitle>
                      {{ project.address }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>

            <div
              class="d-flex justify-space-between"
            >
              <div>
                <v-checkbox
                  v-model="selectedProjectId"
                  :hint="project.pid"
                  dense
                  :value="project.id"
                  persistent-hint
                  class="mt-1 mb-2"
                >
                  <template #label>
                    <span
                      class="caption"
                    >
                      <span
                        v-translate
                        translate-context="MUsersPage filter users by this project"
                      >
                        Users only in this project
                      </span>
                    </span>
                  </template>
                </v-checkbox>
              </div>

              <div>
                <v-checkbox
                  v-model="groupByTeams"
                  :readonly="!selectedProjectId"
                  dense
                  hide-details
                  class="mt-1 mb-2"
                  :style="{
                    opacity: selectedProjectId ? 1 : 0.5,
                  }"
                >
                  <template #label>
                    <v-tooltip
                      bottom
                      content-class="pa-0"
                      :disabled="!!selectedProjectId"
                    >
                      <template #activator="{ on }">
                        <div>
                          <span
                            v-translate
                            translate-context="MUsersPage group by teams checkbox"
                            class="caption"
                            v-on="on"
                          >
                            Group by teams
                          </span>
                        </div>
                      </template>

                      <v-card
                        max-width="300px"
                      >
                        <v-card-text>
                          <span
                            v-translate
                            translate-context="MUsersPage group by teams tooltip"
                          >
                            Select a worksite first
                          </span>
                        </v-card-text>
                      </v-card>
                    </v-tooltip>
                  </template>
                </v-checkbox>
              </div>
            </div>
          </div>

          <v-spacer
            class="mx-4"
          />

          <div
            class="flex-grow-1"
            style="max-width: 400px;"
          >
            <div>
              <v-text-field
                v-model="search"
                :label="searchForUserPlaceholder"
                hide-details
                rounded
                dense
                solo
                light
                clearable
                :prepend-inner-icon="isMobile ? null : 'search'"
                @focus="() => focusOnSearch = true"
                @blur="() => focusOnSearch = false"
              />

              <span
                :style="{
                  opacity: focusOnSearch ? 1 : 0,
                }"
                class="caption-xs"
              >
                <span
                  v-if="selectedProjectId"
                  v-translate
                  translate-context="MUsersPage search all users -hint"
                >
                  To search all users, remove the worksite selection
                </span>

                <span
                  v-else-if="projectId"
                  v-translate
                  translate-context="MUsersPage search worksite users -hint"
                >
                  To search users per worksite, select a worksite
                </span>
              </span>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-card>
      <!-- v-data-table doesn't support group toggle so we have to have if/else here -->
      <v-data-table
        v-if="groupByTeams"
        ref="virtualScrollTable"
        dense
        :headers="headers"
        :items="users"
        item-key="name"
        disable-pagination
        hide-default-footer
        fixed-header
        group-by="team"
        :loading="loadingData"
        :custom-group="() => groupUsers(teamsWithUsers)"
      >
        <template #group.header="{ group, headers, isOpen, toggle, items }">
          <td
            class="primary lighten-4 white--text cursor-pointer caption font-weight-bold"
            style="user-select: none;"
            :colspan="headers.length"
            @click="toggle"
          >
            <v-btn
              small
              icon
              class="mr-2"
            >
              <v-icon
                small
              >
                {{ isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
              </v-icon>
            </v-btn>

            {{ group }}

            <span
              class="ml-2"
            >
              (<span
                v-translate="{ peopleCount: items.length }"
                translate-context="MUsersPage people count in a given team"
                class="d-inline-block"
              >
                %{ peopleCount } pax
              </span>)
            </span>
          </td>
        </template>
      </v-data-table>

      <v-data-table
        v-else
        ref="virtualScrollTable"
        dense
        :headers="headers"
        :items="users"
        item-key="id"
        disable-pagination
        hide-default-footer
        fixed-header
        single-expand
        show-expand
        :expanded.sync="selectedRows"
        class="users-table"
        :loading="loadingData"
        @click:row="onRowClick"
      >
        <template v-slot:expanded-item="{ headers, item: user }">
          <td
            :colspan="headers.length"
            class="pa-0"
          >
            <div
              class="full-width white"
            >
              <m-user-editor-tabs
                :selected-user="selectedUser"
                :project-id="selectedProjectId"
              />
            </div>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { mapWaitingActions } from 'vue-wait';
  import { translate } from 'vue-gettext';
  import { searchItems } from 'vuetify/lib/util/helpers';
  import MUserEditorTabs from '@/components/MUserEditorTabs';

  const { pgettext: $pgettext } = translate;

  export default {
    components: {
      MUserEditorTabs,
    },

    props: {
      projectId: {
        type: Number,
        default: null,
      },
    },

    data: vm => ({
      search: '',
      headers: [
        {
          text: $pgettext('MUsersPage table header', 'Name'),
          value: 'fullName',
          width: 300,
        },
        {
          text: $pgettext('MUsersPage table header', 'Role'),
          value: 'humanReadableRole',
          width: 200,
        },
        {
          text: $pgettext('MUsersPage table header', 'Open'),
          value: 'data-table-expand',
        },
      ],
      selectedProjectId: vm.projectId,
      groupByTeams: false,
      focusOnSearch: false,

      /**
       * Selected user (expanded row)
       */
      selectedRows: [],

      /**
       * Virtual table configs
       */
      timeout: null,
      rowHeight: null,
      tableWrapper: null,
    }),

    computed: {
      ...mapGetters({
        __users__: 'users/users',
        project: 'project/project/project',
        teams: 'project/teams/teams',
        teamsWithUsers: 'project/teams/teamsWithUsers',
        isMobile: 'utils/isMobile',
        projectsByCompanyId: 'projects/projectsByCompanyId',
        companyById: 'companies/companyById',
      }),

      projects() {
        return this.projectsByCompanyId[this.project.companyId];
      },

      users() {
        /**
         * Some views load users grouped by ID. If user navigates from one of those views to this
         * view, this view breaks. This is not a fix but a band-aid to ensure things work properly.
         */
        if (!Array.isArray(this.__users__)) {
          return [];
        }

        if (this.search) {
          return searchItems(this.__users__, this.search);
        }

        return this.__users__;
      },

      selectedUser() {
        return this.selectedRows[0] || null;
      },

      searchForUserPlaceholder() {
        if (this.selectedProjectId) {
          return this.$pgettext('MUsersPage search worksite users - input placeholder', 'Search users from worksite...');
        }

        return this.$pgettext('MUsersPage search worksite users - input placeholder', 'Search users from organization...');
      },

      loadingData() {
        return this.$wait.is(['loading users', 'loading teams', 'loading teamsUsers']);
      },
    },

    watch: {
      selectedProjectId: {
        immediate: true,
        async handler(newProjectId, prevProjectId) {
          if (newProjectId === prevProjectId) return;

          await this.loadUserData();

          this.$nextTick(() => {
            this.resetView();
          });
        },
      },
    },

    mounted() {
      this.initVirtualTable();
    },

    beforeDestroy() {
      this.teardown();
    },

    methods: {
      ...mapWaitingActions('users', {
        loadUsers: 'loading users',
      }),

      ...mapWaitingActions('project/teams', {
        loadTeams: 'loading teams',
      }),

      ...mapWaitingActions('project/teamsUsers', {
        loadTeamsUsers: 'loading teamsUsers',
      }),

      toggle(isSelected, select) {
        select(!isSelected);
      },

      resetView() {
        this.search = '';
        this.start = 0;
        this.timeout = null;
        this.rowHeight = 32;

        if (!this.selectedProjectId) {
          this.groupByTeams = false;
        }
      },

      async loadUserData() {
        if (this.selectedProjectId) {
          await Promise.all([
            this.loadUsers({ projectId: this.selectedProjectId }),
            this.loadTeams({ projectId: this.selectedProjectId }),
            this.loadTeamsUsers({ projectId: this.selectedProjectId }),
          ]);
        } else {
          await Promise.all([
            this.loadUsers(),
          ]);
        }
      },

      /**
       * Vuetify's data table doesn't support virtual scroller so let's hack it.
       */
      initVirtualTable() {
        if (!this.tableWrapper) {
          // eslint-disable-next-line
          this.tableWrapper = this.$refs.virtualScrollTable.$el.getElementsByClassName('v-data-table__wrapper')[0];
        }

        this.tableWrapper.addEventListener('scroll', this.onScroll);

        if (this.isMobile) {
          // Not optimal but allows user to get more vertical space since header is rather high
          this.tableWrapper.style.maxHeight = 'calc(100vh)';
        } else {
          this.tableWrapper.style.maxHeight = 'calc(100vh - 238px)';
        }
      },

      /**
       * Cleanup after yourself!
       */
      teardown() {
        if (this.tableWrapper) {
          this.tableWrapper.removeEventListener('scroll', this.onScroll);
        }
      },

      groupUsers(data) {
        return data.reduce((acc, parent) => {
          let { users } = parent;

          if (this.search) {
            users = searchItems(parent.users, this.search);
          }

          return [
            ...acc,
            {
              name: parent.name,
              items: users,
            },
          ];
        }, []);
      },

      onRowClick(user, { expand, isExpanded }) {
        expand(!isExpanded);
      },
    },
  };
</script>

<style lang="scss">
  .users-table > .v-data-table__wrapper > table > tbody > tr:not(.v-data-table__expanded__content) {
    cursor: pointer;
  }

  .user-tabs {
    .v-slide-group.v-item-group > .v-slide-group__next,
    .v-slide-group.v-item-group > .v-slide-group__prev {
      display: none !important;
    }
  }

  .opacity-1 {
    opacity: 1 !important;
  }
</style>
