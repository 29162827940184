<template>
  <v-menu
    offset-y
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-select
        v-model="tmpSelectedTeams"
        :items="teams"
        multiple
        dense
        hide-details
        rounded
        :disabled="!hasProjectId"
        small-chips
        flat
        outlined
        item-value="id"
        item-text="name"
        :menu-props="{ offsetY: true, disabled: true }"
        v-bind="attrs"
        v-on="on"
      >
        <template #selection="{ item: team }">
          <v-chip
            x-small
            light
            class="mt-1"
          >
            {{ team.name }}
          </v-chip>
        </template>
      </v-select>
    </template>

    <v-card
      max-width="468px"
    >
      <v-card-title
        class="backgroundAccent darken-1 white--text subtitle-2"
      >
        <span
          v-translate
          translate-context="MSelectUserTeams component"
        >
          Which teams this user belongs to?
        </span>
      </v-card-title>

      <v-card-subtitle
        class="backgroundAccent darken-1 white--text caption"
      >
        <span
          v-translate
          translate-context="MSelectUserTeams component"
        >
          You can select more than one.
        </span>
      </v-card-subtitle>

      <div
        style="max-height: 300px; overflow-y: auto;"
      >
        <v-list
          subheader
          dense
        >
          <v-list-item-group
            v-model="tmpSelectedTeams"
            multiple
          >
            <v-list-item
              v-for="(team, i) in teams"
              :key="i"
              :value="team"
              style="min-height: 32px; height: 32px;"
              color="secondary"
              :disabled="updating"
            >
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox
                    :input-value="active"
                    dense
                    color="secondary"
                  />
                </v-list-item-action>

                <v-list-item-content
                  class="py-0"
                >
                  <v-list-item-title>{{ team.name }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>

      <v-divider />

      <v-card-actions
        class="backgroundAccent darken-1 justify-space-between pa-4"
      >
        <!--<v-checkbox-->
        <!--  v-model="notifyInvitedUser"-->
        <!--  :disabled="teamsDidNotChange || updating"-->
        <!--  class="mt-0 pt-0"-->
        <!--  dark-->
        <!--  persistent-hint-->
        <!--  :hint="$pgettext(-->
        <!--    'MSelectUserTeams component',-->
        <!--    'Tip: Use this to inform users about new worksites',-->
        <!--  )"-->
        <!--&gt;-->
        <!--  <template #label>-->
        <!--    <span-->
        <!--      v-translate-->
        <!--      translate-context="MSelectUserTeams component"-->
        <!--      class="caption"-->
        <!--    >-->
        <!--      Inform user by e-mail-->
        <!--    </span>-->
        <!--  </template>-->
        <!--</v-checkbox>-->

        <div
          class="d-flex flex-column justify-end ml-4"
        >
          <v-btn
            :loading="updating"
            :disabled="teamsDidNotChange || updating"
            :dark="!teamsDidNotChange"
            small
            depressed
            color="secondary"
            @click="submit"
          >
            <!--<span-->
            <!--  v-if="notifyInvitedUser"-->
            <!--  v-translate-->
            <!--  translate-context="MSelectUserTeams component save & inform button"-->
            <!--  class="caption"-->
            <!--&gt;-->
            <!--  Save & inform-->
            <!--</span>-->

            <span
              v-translate
              translate-context="MSelectUserTeams component save button"
              class="caption"
            >
              Save
            </span>
          </v-btn>

          <v-btn
            :disabled="teamsDidNotChange || updating"
            dark
            text
            small
            color="warning"
            class="mt-2"
            @click="resetSelectedTeams"
          >
            <span
              v-translate
              translate-context="MSelectUserTeams component reset button"
              class="caption"
            >
              Reset
            </span>
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import { mapWaitingActions } from 'vue-wait';

  export default {
    props: {
      user: {
        type: Object,
        required: true,
      },

      selectedTeams: {
        type: Array,
        required: true,
      },

      teams: {
        type: Array,
        required: true,
      },

      projectId: {
        type: Number,
        default: null,
      },
    },

    data: () => ({
      tmpSelectedTeams: [],
      // notifyInvitedUser: false,
    }),

    computed: {
      ...mapGetters({
        teamsUsersByUser: 'project/teamsUsers/teamsUsersByUser',
      }),

      teamsDidNotChange() {
        const teamIds = this.selectedTeams.map(s => s.id);
        const tmpTeamIds = this.tmpSelectedTeams.map(s => s.id);

        const has1 = teamIds.every(teamId => tmpTeamIds.includes(teamId));
        const has2 = tmpTeamIds.every(teamId => teamIds.includes(teamId));

        return has1 && has2;
      },

      updating() {
        return this.$wait.is('updating user associations');
      },

      hasProjectId() {
        return !!this.projectId;
      },
    },

    watch: {
      selectedTeams: {
        immediate: true,
        handler() {
          this.resetSelectedTeams();
        },
      },

      projectId(pid) {
        if (!pid) this.tmpSelectedTeams = [];
      },
    },

    methods: {
      ...mapActions({
        createTeamUser: 'project/teamsUsers/createTeamUser',
        destroyTeamUser: 'project/teamsUsers/destroyTeamUser',
      }),

      ...mapWaitingActions('projects', {
        loadUserProjects: 'loading user projects',
      }),

      resetSelectedTeams() {
        this.tmpSelectedTeams = [...this.selectedTeams];
      },

      async submit() {
        this.$wait.start('updating user associations');

        const teamsUsersByUser = this.teamsUsersByUser(this.user.id);
        const currentTeamIds = teamsUsersByUser.map(tu => tu.teamId);
        const selectedTeamIds = this.tmpSelectedTeams.map(t => t.id);

        const promises = [];

        // Destroys teams users
        const removedTeamIds = currentTeamIds.filter(x => !selectedTeamIds.includes(x));
        const removedTeamsUsers = teamsUsersByUser.filter(tu => removedTeamIds.includes(tu.teamId));
        promises.push(
          ...removedTeamsUsers.map(({ id }) => (
            this.destroyTeamUser({ projectId: this.projectId, id })
          )),
        );

        // Adds teams users
        const addedTeamIds = selectedTeamIds.filter(x => !currentTeamIds.includes(x));
        promises.push(
          ...addedTeamIds.map((teamId) => {
            const payload = {
              teamUser: {
                teamId,
                userId: this.user.id,
                projectId: this.projectId,
              },
            };

            return this.createTeamUser({
              projectId: this.projectId,
              payload,
            });
          }),
        );

        await Promise.all(promises);
        await this.loadUserProjects({ userId: this.user.id });

        this.$wait.end('updating user associations');
      },
    },
  };
</script>
