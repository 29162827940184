<template>
  <v-card
    tile
    elevation="0"
    max-width="500px"
  >
    <v-card-title>
      <span
        v-translate="{ firstName: user.firstName }"
        translate-context="MUserOtherProjectsTab title"
      >
        User info for %{ firstName }
      </span>
    </v-card-title>

    <v-card-subtitle>
      <span
        v-translate="{ firstName: user.firstName }"
        translate-context="MUserOtherProjectsTab title"
      >
        View & edit basic user information for %{ firstName }.
      </span>
    </v-card-subtitle>

    <v-card-text
      v-if="readOnly"
    >
      <v-simple-table
        dense
      >
        <template v-slot:default>
          <tbody>
            <tr
              v-for="(detail, i) in details"
              :key="i"
            >
              <th>
                {{ detail.key }}
              </th>

              <td
                v-if="detail.icon"
              >
                <v-icon
                  :color="detail.iconColor"
                  small
                >
                  {{ detail.icon }}
                </v-icon>
              </td>

              <td
                v-else
                style="min-width: 150px;"
                v-html="detail.val ? detail.val : '—'"
              />
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-card-actions
        class="pt-4"
      >
        <v-btn
          color="info darken-2"
          @click="readOnly = false"
        >
          <span
            v-translate
            translate-context="MUserBasicInfoTab component edit button"
          >
            Edit
          </span>

          <v-icon
            small
            right
          >
            edit
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card-text>

    <v-card-text
      v-else
    >
      <v-form
        ref="userInfoForm"
      >
        <m-user-form
          :user="tmpUser"
        />
      </v-form>

      <v-card-actions
        class="pt-8 px-0"
      >
        <v-btn
          :disabled="$wait.is('destroying user')"
          :loading="$wait.is('destroying user')"
          class="px-2 py-1"
          color="error"
          small
          text
          min-width="0"
          height="auto"
          @click="destroy"
        >
          <div
            v-if="confirmDestroy"
          >
            <span
              v-translate
              translate-context="MUserBasicInfoTab component delete button confirm"
            >
              Sure?
            </span>
          </div>

          <div
            v-else
            class="d-flex flex-column"
          >
            <v-icon
              x-small
            >
              delete
            </v-icon>

            <span
              v-translate
              translate-context="MUserBasicInfoTab component delete button"
              class="mt-1 caption-xxs font-weight-bold"
            >
              Delete
            </span>
          </div>
        </v-btn>

        <v-spacer />

        <v-btn
          text
          color="error"
          @click="readOnly = true"
        >
          <span
            v-translate
            translate-context="MUserBasicInfoTab component cancel button"
          >
            Cancel
          </span>
        </v-btn>

        <v-btn
          :loading="$wait.is('saving user')"
          :disabled="$wait.is('saving user')"
          color="secondary"
          :dark="!$wait.is('saving user')"
          class="px-6"
          @click="onSave"
        >
          <span
            v-translate
            translate-context="MUserBasicInfoTab component save button"
          >
            Save
          </span>
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
  import cloneDeep from 'lodash.clonedeep';
  import { mapWaitingActions } from 'vue-wait';
  import MUserForm from '@/components/MUserForm';

  export default {
    components: { MUserForm },
    props: {
      user: {
        type: Object,
        required: true,
      },
    },

    data: () => ({
      readOnly: true,
      tmpUser: null,
      confirmDestroy: false,
    }),

    computed: {
      details() {
        let details = [];

        if (!this.tmpUser) return details;

        details = [
        {
            key: this.$pgettext('MUserBasicInfoTab component form item', 'ID'),
            val: this.tmpUser.id,
          },
          {
            key: this.$pgettext('MUserBasicInfoTab component form item', 'Name'),
            val: this.tmpUser.fullName,
          },
          {
            key: this.$pgettext('MUserBasicInfoTab component form item', 'Role'),
            val: this.tmpUser.humanReadableRole,
          },
          {
            key: this.$pgettext('MUserBasicInfoTab component form item', 'Email'),
            val: this.tmpUser.email,
          },
          {
            key: this.$pgettext('MUserBasicInfoTab component form item', 'Phone'),
            val: this.tmpUser.phoneNumber,
          },
          {
            key: this.$pgettext('MUserBasicInfoTab component form item', 'Push notifications'),
            icon: this.tmpUser.hasValidPushSubscription ? 'done' : 'close',
            iconColor: this.tmpUser.hasValidPushSubscription ? 'success' : 'error',
          },
          {
            key: this.$pgettext('MUserBasicInfoTab component form item', 'Created on'),
            val: this.$dayjs(this.tmpUser.createdAt).format('DD.MM.YYYY'),
          },
          {
            key: this.$pgettext('MUserBasicInfoTab component form item', 'Last active'),
            val: this.tmpUser.prevAuthAt
              ? this.$dayjs(this.tmpUser.prevAuthAt).format('DD.MM.YYYY HH:mm') : '—',
          },
          {
            key: this.$pgettext(
              'MUserBasicInfoTab component form item',
              'Has accepted to the use of personal info in accordance with the privacy statement',
            ),
            icon: this.tmpUser.gdprConsent ? 'done' : 'close',
            iconColor: this.tmpUser.gdprConsent ? 'success' : 'error',
          },
        ];

        return details;
      },
    },

    watch: {
      user: {
        immediate: true,
        handler(user, prevUser) {
          if (user === prevUser) return;
          this.tmpUser = cloneDeep(user);
        },
      },
    },

    methods: {
      ...mapWaitingActions('users', {
        updateUser: 'saving user',
        destroyUser: 'destroying user',
      }),

      async onSave() {
        const valid = this.$refs.userInfoForm.validate();

        if (!valid) return;

        const user = await this.updateUser({
          id: this.tmpUser.id,
          payload: this.tmpUser,
        });

        this.tmpUser = cloneDeep(user);
        this.readOnly = true;
      },

      async destroy() {
        if (this.confirmDestroy) {
          await this.destroyUser({ id: this.user.id });
        } else {
          this.confirmDestroy = true;
        }
      },
    },
  };
</script>
