var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-tabs',{staticClass:"user-tabs",attrs:{"background-color":"backgroundAccent lighten-2","icons-and-text":"","height":"64px"},model:{value:(_vm.userTab),callback:function ($$v) {_vm.userTab=$$v},expression:"userTab"}},[_vm._l((_vm.userTabItems),function(tab){return _c('v-tab',{key:tab.tab,staticClass:"caption-xs font-weight-bold"},[_vm._v(" "+_vm._s(tab.title)+" "),(tab.icon)?_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(tab.icon)+" ")]):_vm._e()],1)}),(_vm.selectedUser)?_c('div',{staticClass:"flex-grow-1 d-flex justify-center align-end flex-column mx-4"},[_c('div',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"caption-xs mb-1",attrs:{"translate-context":"MUserEditorTabs label for resend registration link"}},[_vm._v(" Need new registration link? ")]),_c('v-btn',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"x-small":"","outlined":"","color":"secondary","translate-context":"MUserEditorTabs Button for resend registration link","to":{
          name: 'invite-user',
          params: {
            userId: _vm.selectedUser.id,
          },
          query: {
            step: 3,
          },
        }}},[_vm._v(" Re-send ")])],1):_vm._e()],2),_c('v-tabs-items',{attrs:{"touchless":""},model:{value:(_vm.userTab),callback:function ($$v) {_vm.userTab=$$v},expression:"userTab"}},_vm._l((_vm.userTabItems),function(tab){return _c('v-tab-item',{key:tab.title},[_c(tab.component,{tag:"component",attrs:{"user":_vm.selectedUser,"project-id":_vm.projectId}})],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }