<template>
  <div>
    <!-- company -->
    <v-select
      v-if="isNew && $currentUser.isAdmin"
      v-model="user.companyId"
      :items="companies"
      filled
      dense
      item-text="name"
      item-value="id"
      label="Yritys"
      :menu-props="{ offsetY: true }"
    />

    <div
      class="d-flex"
    >
      <!-- firstName -->
      <v-text-field
        v-model="user.firstName"
        :disabled="readOnly"
        :label="firstNamePlaceholder"
        filled
        dense
        autofocus
        class="pr-1"
        validate-on-blur
        :rules="[validators.required]"
      />

      <!-- lastName -->
      <v-text-field
        v-model="user.lastName"
        :disabled="readOnly"
        :label="lastNamePlaceholder"
        filled
        dense
        class="pl-1"
        validate-on-blur
        :rules="[validators.required]"
      />
    </div>

    <!-- email -->
    <v-text-field
      v-model="user.email"
      :disabled="readOnly"
      :label="emailPlaceholder"
      type="email"
      filled
      dense
      append-icon="alternate_email"
      validate-on-blur
      :hide-details="readOnly"
      :rules="[validators.required, validators.email]"
    />

    <!-- role -->
    <v-select
      v-model="user.role"
      :disabled="readOnly"
      :label="roleLabel"
      :items="roles"
      filled
      dense
      :menu-props="{ offsetY: true, maxWidth: '400px' }"
      :rules="validators.requiredArray"
    >
      <template v-slot:selection="{ item }">
        <div class="caption font-weight-bold">
          {{ item.text }}
        </div>
      </template>

      <template v-slot:item="{ item, on, attrs }">
        <v-list-item
          v-bind="attrs"
          v-on="on"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
            <v-list-item-subtitle
              style="white-space: inherit;"
            >
              {{ item.subtitle }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-select>

    <!-- phone -->
    <m-phone-input
      :user="user"
      :required="true"
      dense
      filled
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import getValidators from '@/mixins/validators';
  import MPhoneInput from '@/components/forms/MPhoneInput';

  export default {

    components: {
      MPhoneInput,
    },
    inheritAttrs: true,

    props: {
      user: {
        type: Object,
        required: true,
      },

      readOnly: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      ...getValidators('required', 'requiredArray', 'email'),
    }),

    computed: {
      ...mapGetters({
        companies: 'companies/companies',
      }),

      isNew() {
        return !this.user.id;
      },

      roles() {
        return [
          {
            text: this.$pgettext('MUserForm component role name', 'Foreman'),
            value: 'manager',
            subtitle: this.$pgettext(
              'MUserForm component role details',
              "Project planning, user management, dashboard, task lists, all status views. Typically main contractor's foreman.",
            ),
          },
          {
            text: this.$pgettext('MUserForm component role name', 'Foreman, Subcontractor'),
            value: 'submanager',
            subtitle: this.$pgettext(
              'MUserForm component role details',
              'Dashboard, task lists, status view per takt.',
            ),
          },
          {
            text: this.$pgettext('MUserForm component role name', 'Group lead, Subcontractor'),
            value: 'nose',
            subtitle: this.$pgettext(
              'MUserForm component role details',
              'Dashboard, task lists, status view per takt.',
            ),
          },
          {
            text: this.$pgettext('MUserForm component role name', 'Worker'),
            value: 'worker',
            subtitle: this.$pgettext(
              'MUserForm component role details',
              'Dashboard, task lists, status view per takt. Typically all fitters, timbers and other manual workers.',
            ),
          },
        ];
      },

      firstNamePlaceholder() {
        if (this.readOnly) return null;

        return this.$pgettext('Text input placeholder', 'First name');
      },

      lastNamePlaceholder() {
        if (this.readOnly) return null;

        return this.$pgettext('Text input placeholder', 'Last name');
      },

      emailPlaceholder() {
        if (this.readOnly) return null;

        return this.$pgettext('Text input placeholder', 'Email');
      },

      roleLabel() {
        if (this.readOnly) return null;

        return this.$pgettext('Select input label', "What is the user's role?");
      },
    },

    created() {
      /**
       * In most cases the currentUser has only one company
       * present so we should set that as a default value.
       */
      if (!this.user.companyId && this.companies.length === 1) {
        this.user.companyId = this.companies[0].id;
      }
    },
  };
</script>
