<template>
  <v-card
    tile
    elevation="0"
  >
    <v-card-title>
      <span
        v-translate="{ firstName: user.firstName }"
        translate-context="MUserOtherProjectsTab title"
      >
        Assign %{ firstName } to projects
      </span>
    </v-card-title>

    <v-card-subtitle
      class="pb-0"
    >
      <span
        v-translate="{ firstName: user.firstName }"
        translate-context="MUserOtherProjectsTab title"
      >
        You can assign %{ firstName } to projects by selecting which team(s) they belong to
        in that project.
      </span>
    </v-card-subtitle>

    <v-card-text>
      <div
        v-if="$wait.is('loading user projects')"
        class="mt-n10"
      >
        <m-loader-circular
          text=""
          :size="20"
          :width="2"
        />
      </div>

      <div
        v-else
      >
        <v-row
          v-if="projects.length > 0"
        >
          <v-col
            style="min-width: 200px; max-width: 400px;"
          >
            <div
              class="subtitle-1"
            >
              1.
              <span
                v-translate
                translate-context="MUserOtherProjectsTab select project instruction title"
              >
                Select a project
              </span>

              <m-tooltip
                bottom
              >
                <v-card-text>
                  <span
                    v-translate="{ firstName: user.firstName }"
                    translate-context="MUserOtherProjectsTab attach user to team info first step"
                  >
                    Different projects have different teams. Select a project first to
                    change which team(s) %{ firstName } belongs to in that project.
                  </span>
                </v-card-text>
              </m-tooltip>
            </div>

            <v-combobox
              :items="projects"
              :value="selectedProject"
              item-text="pid"
              hide-details
              outlined
              dense
              clearable
              rounded
              :menu-props="{ maxHeight: 500 }"
              class="caption mt-2"
              @change="p => selectedProjectId = p ? p.id : null"
            >
              <template #item="{ item: project, attrs, on }">
                <v-list-item
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-list-item-action>
                    <v-icon
                      v-if="userProjects.includes(project)"
                      small
                      color="secondary"
                    >
                      check
                    </v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ project.pid }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </v-col>

          <v-col
            style="max-width: 500px;"
          >
            <div
              class="subtitle-1"
            >
              2.
              <span
                v-translate
                translate-context="MUserOtherProjectsTab select project instruction title"
              >
                Select teams
              </span>

              <m-tooltip
                bottom
              >
                <v-card-text>
                  <span
                    v-translate="{ firstName: user.firstName }"
                    translate-context="MUserOtherProjectsTab attach user to team info second step"
                  >
                    Different teams have different tasks. Which teams' tasks %{ firstName } will
                    be working on?
                  </span>
                </v-card-text>
              </m-tooltip>
            </div>

            <div
              class="mt-2"
            >
              <m-select-user-teams
                :user="user"
                :selected-teams="selectedTeams"
                :teams="teams"
                :notify-invited-user="true"
                :project-id="selectedProjectId"
              />
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { mapWaitingActions } from 'vue-wait';
  import MSelectUserTeams from '@/components/MSelectUserTeams';

  export default {
    components: { MSelectUserTeams },
    props: {
      user: {
        type: Object,
        required: true,
      },

      projectId: {
        type: Number,
        default: null,
      },
    },

    data: () => ({
      selectedProjectId: null,
    }),

    computed: {
      ...mapGetters({
        teams: 'project/teams/teams',
        project: 'project/project/project',
        isAssignedToTeam: 'project/teamsUsers/isAssignedToTeam',
        __projects__: 'projects/projects',
        __userProjects__: 'projects/userProjects',
      }),

      projects() {
        const projects = [];

        if (this.userProjects.length > 0) {
          projects.push({
            header: this.$pgettext('MUserOtherProjectsTab project selection dropdown', 'Works in these projects'),
          });

          this.userProjects.forEach(p => projects.push(p));
        }

        projects.push({ divider: true });
        projects.push({
          header: this.$pgettext('MUserOtherProjectsTab project selection dropdown', 'Other projects'),
        });

        const userProjectIds = this.userProjects.map(p => p.id);
        const otherProjects = this.__projects__.filter(p => !userProjectIds.includes(p.id));

        otherProjects.forEach(p => projects.push(p));

        return projects;
      },

      userProjects() {
        return this.__userProjects__;
      },

      selectedTeams() {
        return this.teams.filter(team => this.isAssignedToTeam({ team, user: this.user }));
      },

      selectedProject() {
        return this.projects.find(p => p.id === this.selectedProjectId);
      },
    },

    watch: {
      user: {
        immediate: true,
        handler(user, prevUser) {
          if (user === prevUser) return;
          this.loadUserProjects({ userId: user.id });
        },
      },

      projectId: {
        immediate: true,
        handler(projectId) {
          this.selectedProjectId = projectId;
        },
      },

      selectedProjectId(projectId) {
        if (!projectId) return;
        this.reloadData();
      },
    },

    methods: {
      ...mapWaitingActions('projects', {
        loadUserProjects: 'loading user projects',
      }),

      ...mapWaitingActions('project/teams', {
        loadTeams: 'loading teams',
      }),

      ...mapWaitingActions('project/teamsUsers', {
        loadTeamsUsers: 'loading teamsUsers',
      }),

      async reloadData() {
        const promises = [];

        promises.concat(
          this.loadTeams({ projectId: this.selectedProjectId }),
          this.loadTeamsUsers({ projectId: this.selectedProjectId }),
        );

        await Promise.all(promises);
      },
    },
  };
</script>
